.arrowBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    color: #fff;
    background: rgba(30, 30, 30, 0.8);
    padding: 5px;

    span {
        padding: 5px 10 5px 0;
        display: inline-block;
        font-size: 12px;
    }

    span.circle {
        width: 12px;
        height: 12px;
        position: relative;
        top: 0px;
        margin-right: 10px;
        border-radius: 50%;
        opacity: 1;
        background-color: rgb(203, 21, 23);
        padding: 0;
        display: block;
    }
}