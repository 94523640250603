.grid {
    display: flex;
    margin-top: 20;
    justify-content: center;

    .box {
        width: 100%;
        display: flex;
        justify-content: center;

        .paper {
            align-self: center;
            padding: 10px;
            margin-bottom: 5rem;
            padding-bottom: 5rem;
            margin-top: 2rem;
            padding-top: 2rem;
            background-color: white;
            border: solid 1px #9ca2a6;
            transition: 0.2s;

            &:hover {
                border: 1px solid rgba(14, 30, 37, 0.12);
                box-shadow: rgb(14, 30, 37 / 14%) 0 2px 4px 0, rgb(14, 30, 37 / 36%) 0 2px 16px 0;
            }

            @media screen and (max-width: 600px) {
                width: 100%;
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
}

.flex {
    display: flex;
    gap: 1.5rem;

    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
}

.flex_col {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.root {
    align-self: center;
}

.logo {
    align-self: center;
    width: 100%;
    max-width: 100px;
    max-height: 100px;
    padding: 40px;
}

.textField {
    background-color: white;
    color: white;
}

.errorText {
    color: red !important;
    align-self: center;
    justify-content: center;
}

.signUpButtonContainer {
    width: 100%;
    padding: 15px !important;
    margin-block: 10px !important;
    align-items: center;
    border-radius: 5px;
    font-size: 1rem;
    background-color: #cb1517;

    &:hover {
        background-color: #bf191b;
    }
}

.link {
    text-decoration: none;
    color: #cb1517;
}

.heading {
    font-size: 1.5rem;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    font-family: sans-serif;
    margin: 0 0 40px;
}

.mainContainer {
    background: #d7d7d7;
    min-height: 100%;
}

.contentStyle {
    padding: 24px 0;
    box-shadow: 0px 0px 1px #0c1a03, 0px 3px 8px -1px #32320e;
}

.formContainer {
    text-align: center;
}

.saveButton {
    width: unset;
    padding: 15px 48px !important;
}

@media (max-width: 600px) {
    .saveButton {
        width: 100%;
    }
}