.container {
        background-Color: 'blue';
        width: 100%;
        padding: 15;
        margin: 10;
        align-Items: 'center';
        border-Radius: 5;
}

.signUpButtonContainer {
        background-Color: 'green';
        width: 100%;
        padding: 15;
        margin: 10;
        align-Items: 'center';
        border-Radius: 5;
}

.addUserButtonContainer {
        background-Color: 'purple';
        width: 100%;
        padding: 12;
        margin: 10;
        align-Items: 'center';
        border-Radius: 5;
}

.addUserButton {
        background-color: transparent;
        color: 'blue';
        width: '10%';
        height: '55px';
        margin-Top: 20px;
        margin-Left: 1%;
        margin-Right: 1%;
        color: #cb1517;

        &:hover {
                background-color: rgba(191, 25, 27, 0.08);
        }

        @media screen and (max-width: 930px) {
                margin: 20px 1%;
        }
}

.text {
        font-Weight: 'bold';
        color: 'white';
}

.formContainer {
        padding: 16px;

        @media screen and (max-width: 930px) {
                flex-direction: column;
                align-items: flex-start;
        }
}

.formContainerModal {
        padding: 0;
}

.userSection {
        margin: 24px 0;
        padding: 0 12px;
        gap: 12px;

        @media screen and (max-width: 930px) {
                flex-direction: column;
                align-items: flex-end;
                margin: 24px 0 0;
        }
}

.userSectionModal {
        flex-direction: column;
        align-items: flex-end;
        padding: 0 24px;
        margin: 24px 0 0;
}

.inputRow {
        display: flex;
        align-items: center;
        width: 100%;

        @media screen and (max-width: 475px) {
                align-items: flex-start;
                flex-direction: column;
        }
}

.gutteredRow {
        display: flex;
        align-items: center;
        width: 100%;

        @media screen and (max-width: 930px) {
                align-items: flex-start;
                flex-direction: column;
        }
}

.inputFieldSm {
        @media screen and (max-width: 930px) {
                margin: 0 !important;
        }
}

.inputField {
        width: 46%;
        margin-right: 30px;
        margin-bottom: 30px;

        @media screen and (max-width: 930px) {
                margin: 0 0 30px;
                width: 100%;
        }
}

.grid {
        margin: 0 !important;
        width: 100%;
}

.container {
        @media screen and (max-width: 930px) {
                padding: 0 !important;
        }
}

.rootStyle {
        flex-direction: column;
        margin-top: 48px;

        @media screen and (max-width: 930px) {
                margin-top: 24px;
                display: block;
                width: 100%;
        }
}

.contentStyle {
        padding: 24px 0;
        box-shadow: 0px 0px 1px #0c1a03, 0px 3px 8px -1px #32320e;
}

.fileUploadContainer {
        display: flex;
        align-items: center;
}

.buttonGroup {
        display: flex;
        width: 100%;
        gap: 16px;
        margin-top: 24px;
}

.closeModalButton {
        background: transparent;
        color: black;

        &:hover {
                background: #cb1517;
                color: white;
        }
}

.searchFormContainer {
        margin-bottom: 24px;
}

.searchBar {
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        border: 1px solid rgba(145, 158, 171, 0.32);
        border-radius: 8px;
        background: white;
        padding: 0 24px;

        input {
                font-weight: 400;
        }

        button {
                background-color: #cb1517;

                &:hover {
                        background-color: #bf191b;
                }
        }
}

.buttonPrimary {
        background-color: #cb1517;

        &:hover {
                background-color: #bf191b;
        }
}