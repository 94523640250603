.posterView {
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgb(12, 26, 75 / 24%), 0px 3px 8px -1px rgb(50, 50, 71 / 5%);
    border-radius: 16px;
    padding: 16px;
    margin-right: 24px;

    .imageContainer {
        width: 261px;
        height: 464px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;

        .projectName {
            font-weight: 600;
            font-size: 20px;
            line-height: 136.02%;
            display: flex;
            align-items: center;
            color: #16192C;
        }

        .status {
            border-radius: 4px;
            padding: 4px 8px;
            white-space: nowrap;

            &.inProduction {
                color: #C9544D;
                background: rgba(201, 84, 77, 0.25);
            }

            &.finished {
                color: #4da4c9;
                background: rgba(77, 164, 201, 0.25);
            }
        }
    }

    .info {
        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 12px;

            span {
                font-weight: 600;
                font-size: 16px;
                line-height: 136.02%;
                color: #16192C;
            }
        }
    }
}