.searchResults {
    width: 100%;
    padding: 0 8px;
    margin-top: 24px;
}

.link {
    justify-content: flex-start;
    text-decoration: none;
    margin-bottom: 16px;
    color: #cb1517;
    transition: all .4s ease-in-out;

    &:hover {
        background-color: rgba(191, 25, 27, 0.08);
    }
}

.buttonPrimary {
    background-color: #cb1517;

    &:hover {
        background-color: #bf191b;
    }
}

.copyToClipboardButton {
    padding: 8px;
    margin-left: 4px;

    svg {
        width: 20px;
        height: 20px;
        stroke: #cb1517;
    }

    &:hover {
        background-color: rgba(191, 25, 27, 0.08);
    }
}