.box {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.grid {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.root {
	padding: 10px;
	text-align: right;
}

.logo {
	width: 100%;
	width: 100%;
	max-width: 100px;
	max-height: 100px;
	padding: 100px;
}

.paper {
	padding: 20px;
	margin-top: 8%;
	background-color: white;
	border: solid 1px #9ca2a6;
	transition: 0.2s;
	max-width: 548px;

	&:hover {
		border: 1px solid rgba(14, 30, 37, 0.12);
		box-shadow: rgb(14, 30, 37 / 14%) 0 2px 4px 0, rgb(14, 30, 37 / 36%) 0 2px 16px 0;
	}

	@media screen and (max-width: 600px) {
		width: 100%;
		height: 100vh;
		max-width: unset;
		margin-top: 0;
		margin-bottom: 0;
	}
}

.avatar {
	background-color: black !important;
	margin-left: 80px;
	margin-top: 20px;
	padding: 30px;
}

.heading {
	font-size: 1.5rem;
	padding: 10px;
	text-align: center;
	font-weight: bold;
	font-family: sans-serif;
}

.textField {
	margin-top: 1.5rem;
	background-color: white;
	color: white;
}

.errorText {
	align-self: center;
	color: red !important;
}

.typography {
	margin-top: 10px;
}

.loginButtonContainer {
	width: 100%;
	padding: 15px !important;
	margin-block: 10px !important;
	align-items: center;
	border-radius: 5px;
	font-size: 1rem;
	background-color: #cb1517;

	&:hover {
		background-color: #bf191b;
	}
}

.link {
	text-decoration: none;
	line-height: 1.5714285714285714;
	font-size: 0.875rem;
	color: #cb1517;
}

.mainContainer {
	background: #d7d7d7;
	min-height: 100%;
}

.buttonGroup {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	gap: 16px;
	margin-top: 24px;
}

.selectGroup {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 16px;
	width: 100%;
	margin-top: 24px;
}

.select	{
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100% !important
}

.closeModalButton {
	background: transparent;
	color: black;

	&:hover {
		background: #cb1517;
		color: white;
	}
}

.buttonPrimary {
	background-color: #cb1517;

	&:hover {
		background-color: #bf191b;
	}
}