.container {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15%;
}

.grid {
    display: flex;
    background: #d7d7d7;
    min-height: 100%;

    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .paper {
            padding: 20px;
            margin-top: 8%;
            background-color: white;
            border: solid 1px #9ca2a6;
            transition: 0.2s;
            width: 548px;

            &:hover {
                border: 1px solid rgba(14, 30, 37, 0.12);
                box-shadow: rgb(14, 30, 37 / 14%) 0 2px 4px 0, rgb(14, 30, 37 / 36%) 0 2px 16px 0;
            }

            @media screen and (max-width: 600px) {
                width: 100%;
                height: 100vh;
                max-width: unset;
                margin-top: 0;
                margin-bottom: 0;
            }

            .avatar {
                align-self: center;
                margin-left: 42%;
                background-color: black;
                padding: 8%;
            }

            .heading {
                margin-left: 42%;
                font-size: 20px;
                margin-bottom: 40px;
                font-weight: bold;
                font-family: sans-serif;
            }
        }
    }
}

.flex {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 1660px) {
        flex-direction: column;
    }
}

.flex_col {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.verifyUserButton {
    margin-Top: 20px;
    margin-Left: 1%;
    margin-Right: 1%;
    color: white;
    background-color: blue;
    width: 20%;
}

.verifyUserButton:hover {
    background-color: blue;
    opacity: 0.7;
}

.resendConfirmationButton {
    width: 100%;
    padding: 15px !important;
    margin-block: 10px !important;
    align-items: center;
    border-radius: 5px;
	font-size: 1rem;
	background-color: #cb1517;
    color: white;

    &:hover {
        background-color: #bf191b;
    }
}

.logoGrid {
    display: flex;
    align-items: center;
    justify-content: center;
}

.paragraph {
    text-align: center;
}

.linkButton {
    padding: 0;
    margin: 0;
    background: transparent;
    border: 0;
    color: #bf191b;
} 

.link {
	text-decoration: none;
	line-height: 1.5714285714285714;
	font-size: 0.875rem;
	color: #cb1517;
}