.grid {
    display: flex;
    padding: 24px 8px;
    overflow-x: auto;
    width: 100%;
    color: black;
}

.listGrid {
    display: flex;
    padding: 0 0 24px 24px;
    width: 100%;
    gap: 12px;
}

.graphGrid {
    display: flex;
    padding: 24px 0 24px 32px;
    width: 100%;
    gap: 12px;
}

.pieGrid {
    width: 100%;    
}

.link {
    text-decoration: none;
}

.chartContainer {
    background: white;
    box-shadow: 0px 0px 1px #0c1a03, 0px 3px 8px -1px #32320e;
    border-radius: 16px;
    padding: 24px 24px 24px 32px;
}

.chartHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

@media (max-width: 900px) {
    .graphGrid {
        flex-direction: column;
        gap: 64px;
    }

    .grid, .pieGrid {
        width: 100%;
    }

    .chartContainer {
        padding: 12px;
    }
}