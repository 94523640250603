.icons {
    color: red;
    margin-right: 0.8rem;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.listItem {
    display: flex;
    align-items: center;
    margin-left: 8px;
}

.uploadButton {
    background-color: transparent;
    border: 2px solid rgb(203, 21, 23);
    color: rgb(203, 21, 23);

    &:hover {
        background-color: rgb(203, 21, 23);
        color: white;
    }
}