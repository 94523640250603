.circularProgress {
    width: 24px !important;
    height: 24px !important;
}

.buttonPrimary {
    background-color: #cb1517;

    &:hover {
        background-color: #bf191b;
    }
}