.link {
    color: #cb1517;
    text-decoration: none;
}

.status {
    span {
        border-radius: 4px;
        padding: 4px 8px;
        white-space: nowrap;
    }

    &.inProduction>span {
        color: #C9544D;
        background: rgba(201, 84, 77, 0.25);
    }

    &.finished>span {
        color: #4da4c9;
        background: rgba(77, 164, 201, 0.25);
    }
}

.tableCard {
    margin-top: 24px;
}

.card {
    box-shadow: 0px 0px 1px #0c1a03, 0px 3px 8px -1px #32320e;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(22, 28, 36, 0.64);
    z-index: 2000;
}

.modalContainer {
    width: 100%;
    max-width: 545px;
    background: white;
    border-radius: 16px;
    padding: 16px 24px;
}

.buttonPrimary {
    background-color: #cb1517;

    &:hover {
        background-color: #bf191b;
    }
}

@media (max-width: 550px) {
    .modalContainer {
        border-radius: unset;
        height: 100%;
    }
}