.mobileAppNotificationWrapper {
    display: none;
    position: fixed;
    bottom: 0;
    width: 100vw;
    background-color: white;
    z-index: 1000;
    border: 1px solid black;

    .mobileAppNotification {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
        padding: 24px;
        position: relative;
        width: 100%;
    }

    span {
        display: inline-block;
        text-align: center;
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-weight: bold;
        color: #CB1517;
        background-color: white;
        border: 2px solid #CB1517;
        border-radius: 8px;
        padding: 8px 24px;
        width: 100%;
    }

    .closeButton {
        background: transparent;
        border: 0;
        position: absolute;
        top: 0;
        right: 0;
        padding: 12px;
        cursor: pointer;
    }
}

@media (max-width: 600px) {
    .mobileAppNotificationWrapper {
        display: flex;
    }
}