.card {
    padding: 24px;
    width: 100%;
    height: 50%;
    min-height: 224px;
    box-shadow: 0px 0px 1px #0c1a03, 0px 3px 8px -1px #32320e;
}

.container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    width: calc(100% - 272px);
    height: 575px;
    gap: 24px;
    margin-left: 24px;
}

.status {
    span:last-child {
        border-radius: 4px;
        padding: 4px 8px;
        white-space: nowrap;
    }

    &.inProduction>span:last-child {
        color: #C9544D;
        background: rgba(201, 84, 77, 0.25);
    }

    &.finished>span:last-child {
        color: #4da4c9;
        background: rgba(77, 164, 201, 0.25);
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(22, 28, 36, 0.64);
    z-index: 2000;
}

.modalContainer {
    width: 100%;
    max-width: 545px;
    background: white;
    border-radius: 16px;
    padding: 16px 24px;
}

.userModalContainer {
    max-width: 720px;
    max-height: 600px;
    overflow-y: scroll;
}

.buttonPrimary {
    background-color: #cb1517;

    &:hover {
        background-color: #bf191b;
    }
}

.imageContainer {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
}

.imageCard {
    width: 372px;
    height: 575px;
    padding: 16px;
    box-shadow: 0px 0px 1px #0c1a03, 0px 3px 8px -1px #32320e;
}

.detailsHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@media (max-width: 550px) {
    .modalContainer {
        height: 100%;
        border-radius: unset;
    }

    .detailsHeaderContainer {
        flex-direction: column;
    }

    .container {
        width: 100%;
        margin: 24px 0 0;
    }

    .imageCard {
        width: 100%;
    }
}

@media (max-width: 645px) {
    .card {
        width: 100%;
    }

    .container {
        flex-direction: column;
    }
}