.projectItem {
    margin-bottom: 8px;

    span:first-child {
        margin-right: 8px;
    }
}

.link {
    text-decoration: none;
    color: #cb1517;
}